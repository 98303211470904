import React, {useState, useEffect} from 'react';
import myPngImage from './4.png';
import myPngImage3 from './aaa.jpg';
import myPngImage4 from './222.png';
import myPngImage2 from './nhtsa.png';
import sas from './sas.png';
import autko from './img_4.png';

function Car(props) {
    const [data, setData] = useState([]);
    const [email, setEmail] = useState('');
    const [surname, setSurname] = useState('');

    useEffect(() => {
        const makeAPICall = async () => {
            const response = await fetch('/local1.json', {mode: 'cors'});
            const data = await response.json();
            setData(data);
        }

        makeAPICall();
        return () => {
            console.log('Component will unmount.');
        };
    }, []);

    const handleEmail = (e) => {
        setEmail(e.target.value);
        console.log(email);
    }

    const handleSurname = (e) => {
        setSurname(e.target.value);
        console.log(email);
    }

    const handleSubmit = (e) => {
        console.log(e);
        setEmail({['email']: e.target.value});
        try {
            const response = fetch('/api/chat/send', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email, surname: surname})
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the success response
            const responseData = response.json();
            console.log('Post request successful', responseData);
        } catch (error) {
            // Handle errors
            console.error('Error during POST request:', error);
        }
    };

    return (
        <div className="container">



            <br></br>
            <img src={myPngImage3} className='logo-image' width='80em' height='70em'></img>
            <br></br>

            <p className='title-text car' color='white'>&nbsp;&nbsp;neuro-car</p>
            <br></br>
            <h3>Wykorzystaj potencjał naszych doradców oraz sztucznej inteligencji w dobrze auta.</h3>


            <br></br>
            <table className="table table-dark">
                <thead>
                <tr>
                    <th>Marka</th>
                    <th>Rocznik</th>

                    <th>Cena</th>
                    <th>Zdjecie</th>
                </tr>
                </thead>
                <tbody>
                {data.map((movie) => (
                    <tr>
                        <td>{movie.brand}</td>
                        <td>{movie.year}</td>

                        <td>{movie.power}</td>
                        <td>
                            <a href={'/detail/' + movie.id + '.html'}>
                                <img src={movie.photo} alt={'w'}></img>
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <hr></hr>
            <p className='opis'> Neuro-car<br></br> +48 531 730 179 <br></br> office@neuro-car.pl</p>
        </div>
    )
}

export default Car;