import React, {useState, useEffect} from 'react';
import detail1 from './galeria5.png';
import detail2 from './2.png';
import detail3 from './3.png';
import detail4 from './4.png';
import konsultantka from './44.png';

import {useParams} from 'react-router-dom';
import myPngImage4 from "./galeria1.png";
import myPngImage5 from "./galeria2.png";
import autko from "./img_4.png";

function Detail(props) {
    const [data, setData] = useState([]);
    const [email, setEmail] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [namee, setNamee] = useState('');
    const [responseData, setResponseData] = useState('');
    const [message, setMessage] = useState('');
    const {id} = useParams();


    useEffect(() => {
        const makeAPICall = async () => {
            const response = await fetch('/'+ id + '.json', {mode: 'cors'});
          //  const response = await fetch('http://127.0.0.1:8001/api/car/detail/' + id, {mode: 'cors'});
            const data = await response.json();
            setData(data);
        }

        makeAPICall();
        return () => {
            console.log('Component will unmount.');
        };
    }, []);


    const handleEmail = (e) => {
        setEmail(e.target.value);
        console.log(email);
    }

    const handleResponseData = (e) => {
        setResponseData(e.target.value);
    }

    const handleNamee = (e) => {
        setNamee(e.target.value);
        console.log(namee);
    }

    const handleMessage = (e) => {
        setMessage(e.target.value);
    }

    const handlePhone = (e) => {
        setPhone(e.target.value);
    }

    const handleSurname = (e) => {
        setSurname(e.target.value);
    }

    const handleSubmit = (e) => {
        setMessage({['message']: e.target.value});

        const response = fetch('/detail/chat.php', {
            method: 'POST',
            mode: 'cors', // This enables CORS mode
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({message: message})

        }).then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then((data) => {
                setResponseData(data.message);
                setMessage('');
            })
    };

    const handleSubmitOrder = (e) => {
        setEmail({['email']: e.target.value});
        setPhone({['phone']: e.target.value});
        setSurname({['surname']: e.target.value});
        const response = fetch('/detail/test.php', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, phone: phone, setSurname: surname})

        }).then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return '{"success":true}';
        })
            .then((data) => {
                setEmail('');
                setSurname('');
                setPhone('');
            })
    };

    return (
        <div className="container cos">
            <div className="container mt-5">
                <br></br>
               <a href='/'><button type='button' className='btn btn-primary'>Powrót do listy aut</button></a>&nbsp;&nbsp;&nbsp;&nbsp;

                {data.map((movie) => (
                    <h3>Oferta: #{movie.id}
                    </h3>
                ))}
                {data.map((movie) => (
                    <p>Przebieg: {movie.km}
                    </p>
                ))}
                {data.map((movie) => (
                    <p>Pojemnosc silnika: {movie.engine}
                    </p>
                ))}
                {data.map((movie) => (
                    <p>Moc: {movie.power}
                    </p>
                ))}
                {data.map((movie) => (
                    <p>Rok produkcji: {movie.year}
                    </p>
                ))}  {data.map((movie) => (
                <p>Opis: {movie.desc}
                </p>
            ))}

                <br></br>
                <div className="row">

                    <div className="col-md-4 mb-4">
                        {data.map((movie) => (
                        <img src={movie.pic1} alt="Image 1" className="img-fluid img-thumbnail"/>
                        ))}
                    </div>
                    <div className="col-md-4 mb-4">
                        {data.map((movie) => (
                        <img src={movie.pic2} alt="Image 3" className="img-fluid img-thumbnail"/>
                        ))}
                    </div>
                    <div className="col-md-4 mb-4">
                        {data.map((movie) => (
                        <img src={movie.pic3} alt="Image 2" className="img-fluid img-thumbnail"/>
                        ))}
                    </div>

                </div>
            </div>

            <div className="container">
                {/*<h5>Znalezlismy podobne auta i dokonalismy wstepnej wyceny. Zobacz przykładowe zakupy podobnego auta:</h5>*/}
                {/*<table>*/}
                {/*    <th width='60px'>Kraj</th>*/}
                {/*    <th width='60px'>Silnik</th>*/}
                {/*    <th width='60px'>Rok</th>*/}
                {/*    <th width='90px'>Cena</th>*/}
                {/*    <tr>*/}
                {/*        <td>Niemcy&nbsp;&nbsp;&nbsp;&nbsp;</td>*/}
                {/*        <td>2.0&nbsp;</td>*/}
                {/*        <td>2019&nbsp;</td>*/}
                {/*        <td>142 000&nbsp;PLN&nbsp;</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>Szwajcaria&nbsp;&nbsp;&nbsp;&nbsp;</td>*/}
                {/*        <td>2.0&nbsp;</td>*/}
                {/*        <td>2020&nbsp;</td>*/}
                {/*        <td>145 000&nbsp;PLN&nbsp;</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>Niemcy&nbsp;&nbsp;&nbsp;&nbsp;</td>*/}
                {/*        <td>2.0&nbsp;</td>*/}
                {/*        <td>2021&nbsp;</td>*/}
                {/*        <td>142 000&nbsp;PLN&nbsp;</td>*/}
                {/*    </tr>*/}
                {/*</table>*/}

                <br></br>
                <br></br>
                <h5 class='price'>
                    Przywidywania cena wedlug sztucznej inteligencji i naszych doradcow to: </h5>
                {data.map((movie) => (
                    <p className='price price2'> {movie.price}</p>
                ))}
                <br></br>


                <div className="container">
                    <div className="col-md-5">

                        <img src={konsultantka} width='100px' height='100px' className='kons'></img>

                        <h3>Porozmawiaj na żywo z Panią Katarzyną o tym samochodzie</h3>
                        <br></br>


                        <div className="form-group">
                            <label htmlFor="receiver">Pania Kasia pisze:</label>
                            <input type="email" maxLength='10' value={responseData} className="form-control" id="receiver" aria-describedby="emailHelp" placeholder=""/>
                        </div>

                        <div className="form-group">
                            <input type="email" onChange={handleMessage} value={message} className="form-control" id="sender" aria-describedby="emailHelp" placeholder="Wpisz wiadomosc do konsultanta"/>
                        </div>


                        <button onClick={handleSubmit} className="btn btn-primary">Wyślij</button>

                    </div>

                    <div className="col-md-4">
                    </div>
                    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Złożyłeś zamówienie</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Dziekujemy i zapraszamy ponownie :)
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="card">
                            <h4 className='order'>Podoba Ci sie oferta? Zamów pojazd</h4>
                            <br></br>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <input type="email" onChange={handleEmail} value={email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Phone</label>
                                <input size="5" type="phone" onChange={handlePhone} value={phone} className="form-control" id="phone" aria-describedby="emailHelp" placeholder="Enter phone"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Surname</label>

                                <input type="surname" onChange={handleSurname} value={surname} className="form-control" id="surname" aria-describedby="emailHelp" placeholder="Enter surname"/>
                            </div>
                            <button type="submit" class="btn btn-primary" onClick={handleSubmitOrder}  data-toggle="modal" data-target="#exampleModal">Zamów</button>

                        </div>
                    </div>
                </div>
            </div>
            <br></br>
        </div>
    )
}

export default Detail;