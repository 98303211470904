import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import Car from './car';
import User from './user';
import Detail from './detail';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exac path="/detail/:id.html" element={<Detail />} />
      <Route exec path="/" element={<Car/>}> </Route>
      <Route path="/user" element={<User/>}> </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
